import {
  ADMIN_APPS,
  APPS,
  FEATURES,
  IDENTITY_ADMIN_APP,
  STATUS_MESSAGES,
} from './constants'

interface JWTPayload {
  [key: string]: any // eslint-disable-line 
}

export function decodeJWT(token: string): JWTPayload {
  // Split the token into its parts
  const parts = token.split('.')

  // Ensure the token has three parts
  if (parts.length !== 3) {
    throw new Error('Invalid token')
  }

  // Decode the payload
  const payload = parts[1]
  const decodedPayload = JSON.parse(
    atob(payload.replace(/-/g, '+').replace(/_/g, '/')),
  )

  return decodedPayload
}

function buildCard({
  title,
  description = '',
  thumbnail = '',
  href,
}): HTMLAnchorElement {
  /*Link Element */
  const link: HTMLAnchorElement = document.createElement('a')
  link.className =
    'bg-white rounded-lg shadow-lg overflow-hidden p-4 text-center w-[300px] h-[300px] flex flex-col items-center space-between cursor-pointer'
  link.href = href

  /* Title */
  const cardTitle: HTMLHeadingElement = document.createElement('h2')
  cardTitle.className = 'text-lg font-semibold p-4'
  cardTitle.textContent = title

  /* Description */
  const cardDescription: HTMLParagraphElement = document.createElement('p')
  cardDescription.className = 'text-xs text-grey-50 mt-2'
  cardDescription.textContent = description

  /* Thumbnail */
  const cardThumbnail: HTMLImageElement = document.createElement('img')
  cardThumbnail.src = thumbnail
  cardThumbnail.className = 'w-60 h-60'
  cardThumbnail.alt = `${title} icon`

  link.appendChild(cardTitle)
  link.appendChild(cardDescription)
  link.appendChild(cardThumbnail)

  return link
}

export async function displayApps(token?: string): Promise<void> {
  /* Decode JWT Token and extract apps */
  let permissions: string[]

  if (FEATURES.showOnlyEnabledApps) {
    permissions = decodeJWT(token).permissions.filter((p: string) =>
      p.startsWith('app:'),
    )
  } else {
    permissions = Object.keys(APPS)
  }

  /* Create a tile for each app and append it to content */
  permissions.forEach((element: string) => {
    if (APPS[element] !== undefined) {
      const card = buildCard(APPS[element])
      document.getElementById('content').appendChild(card)
      document.getElementById('content').classList.remove('hidden')
      document.getElementById('loader').classList.add('hidden')
    }
  })

  if (permissions.includes(IDENTITY_ADMIN_APP)) {
    const userAdminLink = document.getElementById('user-admin-link')
    userAdminLink.setAttribute('href', ADMIN_APPS[IDENTITY_ADMIN_APP]['href'])
    userAdminLink.classList.remove('hidden')
    userAdminLink.classList.add('flex')
  }

  if (!permissions.length) {
    displayStatusMessage(STATUS_MESSAGES.insufficientPermissions.message)
  }
}

export function displayStatusMessage(message: string): void {
  document.getElementById('statusMessage').classList.remove('hidden')
  document.getElementById('loader').classList.add('hidden')
  document.getElementById('customMessage').innerHTML = message
}
