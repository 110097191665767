import { configureClient, login, logout } from './authn'
import { FEATURES, STATUS_MESSAGES } from './constants'
import { displayApps, displayStatusMessage } from './utilities'

window.onload = async () => {
  const logoutButton = document.querySelectorAll('.logout-button')

  try {
    if (FEATURES.showOnlyEnabledApps) {
      await configureClient()
      login()
    } else {
      displayApps()
    }
  } catch (e) {
    displayStatusMessage(STATUS_MESSAGES.serverError.message)
  }

  if (logoutButton) {
    logoutButton.forEach((instance) => {
      instance.addEventListener('click', () => {
        logout()
      })
    })
  }
}
