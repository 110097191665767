import {
  Auth0Client,
  createAuth0Client,
  RedirectLoginOptions,
} from '@auth0/auth0-spa-js'

import { STATUS_MESSAGES } from './constants'
import { displayApps, displayStatusMessage } from './utilities'

export let auth0Client: Auth0Client | undefined = undefined

export async function configureClient() {
  auth0Client = await createAuth0Client({
    cacheLocation: 'localstorage',
    authorizationParams: {
      audience: process.env.AUTH0_AUDIENCE_CSP,
      redirect_uri: window.location.origin,
      scope: 'openid profile email offline_access',
    },
    domain: process.env.AUTH0_DOMAIN,
    clientId: process.env.AUTH0_CLIENT_ID_CSP_PORTAL,
    useRefreshTokens: true,
  })
}

async function getToken() {
  try {
    const token = await auth0Client.getTokenSilently()
    return token
  } catch {
    throw new Error('Expired token!')
  }
}

export async function login() {
  let isAuthenticated = await auth0Client.isAuthenticated()

  console.debug(isAuthenticated, 'isAuthenticated')

  if (isAuthenticated) {
    try {
      const token = await getToken()
      displayApps(token)
    } catch (e) {
      console.debug(e, 'Authenticated failed due to stale token.')
      isAuthenticated = false // This changes the state after the promise is caught
      console.debug('isAuthentcation set to ', isAuthenticated) // Logging after state change
    }
  }

  if (!isAuthenticated) {
    console.debug('Initiating handleRedirectCallback')
    const params = new URLSearchParams(window.location.search)

    if (params.get('state') && params.get('code')) {
      await auth0Client.handleRedirectCallback()

      window.history.replaceState({}, document.title, '/')
      try {
        const token = await getToken()
        displayApps(token)
      } catch (e) {
        console.debug('Error while handleRedirectCallback')
        displayStatusMessage(STATUS_MESSAGES.serverError.message)
      }
    } else {
      loginWithRedirect(params)
    }
  }
}

export async function logout() {
  const isAuthenticated = await auth0Client.isAuthenticated()
  if (isAuthenticated) {
    auth0Client.logout({ logoutParams: { returnTo: window.location.origin } })
  } else {
    const params = new URLSearchParams(window.location.search)
    loginWithRedirect(params)
  }
}

export async function loginWithRedirect(params) {
  console.debug('loginWithRedirect')
  const options: RedirectLoginOptions = {
    authorizationParams: {},
  }
  // This is an IdP-initiated logon to this oidc application
  // https://community.auth0.com/t/idp-initiated-saml-sign-in-to-oidc-applications/80076
  const connection = params.get('connection')

  if (connection && options?.authorizationParams) {
    options.authorizationParams.connection = connection
  }

  auth0Client.loginWithRedirect(options)
}
